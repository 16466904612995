body{
  background-color: rgb(28,30,43);
  font-family: 'Poppins', sans-serif;
}
h1,h2,h3,h4,h5,h6,p,span,a{
  font-family: 'Poppins', sans-serif;
}

/* =============================Dashboard Page================================================================================================================================================================================================================== */
.dashboard-left-side-div-wrapper-Main{
  padding:30px;
}
.dashboard-left-side-div-one-wrapper h3 {
  color: white;
  font-size: 17px;
  margin-bottom: 0px;
  text-align: start;
}
.dashboard-left-side-div-one-inner-div {
  text-align: end;
  margin-top: -8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
      padding-left: 36px;
    padding-right: 0px;
}
.dashboard-left-side-div-one-wrapper span {
  color: rgb(113, 207, 98);
  font-size: 13px;
}
.dashboard-left-side-div-one-wrapper-battery-div p {
  margin-bottom: 0px;
  color: white;
  font-size: 8px;
  margin: 10px;
  margin-top: 0px;
  margin-bottom:0px;
}
.dashboard-left-side-div-one-wrapper-battery-div {
  display: inline-block;
  text-align: center;
}
.dashboard-left-side-div-two-wrapper-h5 {
  color: white;
  font-weight: 300;
  font-size: 15px;
}
.dashboard-left-side-div-three-wrapper {
  margin-top: 16px;
}
.dashboard-left-side-div-four-wrapper {
  margin-top: 16px;
}

.dashboard-left-side-div-two-Map-Container-one
{
  background-color: #282F42;
  height: 170px;
  overflow: scroll;
}
.dashboard-left-side-div-two-Map-Container-two
{
  background-color: #282F42;
  height: 170px;
   /* border:3px solid rgb(251,98,98) !important; */
}
.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar{
background-color:#282F42;
color:#fff !important;
width:10px;
}
.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar-track{
  color:#ffffff !important;
} 
.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar-thumb {
  background-color:#7d7d7d !important;

}
.dashboard-left-side-div-two-robot-button-Container.d-flex-center{
  background-color: #282F42;
}
.dashboard-left-side-div-two-robot-button-Container button {
  border-radius: 34px;
  padding: 5px 24px;
  background-color: rgb(189,196,224);
  border: none !important;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 17px;
  margin-left: 10px;
  margin-right: 10px;
}
.dashboard-right-side-div-wrapper-Main{
  background-color: #282F42;
  height:auto;
}
.Dashboard-Page-bottom-div-Main-wrapper{
  width:100%;
  background-color: black;
  position: fixed;
  bottom:0;
  padding: 19px  25px;
}
.DashBoard-page-Main-Whole-wrapper{
  padding-left:0px !important;
}
.Dashboard-Page-bottom-div-Main-wrapper-content-p {
  color: white !important;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-left:-6px;
}
.Dashboard-Page-bottom-div-Main-wrapper-content-span{
color: rgb(189,196,224);
}
.Dashboard-Page-bottom-div-Main-wrapper.d-flex-center button {
  background-color: #282F42;
  border:2px solid #a2a2a2;
  border-radius: 30px;
  padding: 4px 22px;
  font-size: 12px;
  color:white;
  font-weight: 200;
}
.Dashboard-page-teleop-button-disabled{
  background-color: #78797a !important; 
}
.Dashboard-Page-Power-button{
  background-color: #282F42;
  border-radius: 30px;
   width:35px !important;
   height:35px !important;
   padding:5px;
}
.col-md-9.dashboard-right-side-div-wrapper-Main {
  height: 90vh;
}
.Stop-button-dashhboard-page{
  background-color: rgb(228 51 41) !important;
  color:white !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  border:none !important; 
  padding: 6px 33px !important;
}
.play-icon{
  cursor:pointer;
}
.Dashboard-page-teleop-button{
  /* border:2px solid rgb(220,53,69) !important;  */
}
.Error-state-teleop-ack-button{
  background-color: rgb(220,53,69) !important;
  color:white !important; 
  font-size: 10px;
  position: absolute;
  right: 0rem;
  top: -10px;
  border:none !important;
}
.dashboard_page_Map_heading_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:12px;
  margin-top:12px;
}
/* =============================End================================================================================================================================================================================================================== */

/* ============================LoginPage========================================================================================================================================================================================================================== */

.Login-page-form {
  width: 35%;
  margin: auto;
  background: rgb(40,47,66);
  /* box-shadow:0px 4px 33px 12px rgb(40,47,66); */
  padding: 25px;
  padding-top: 37px;
  border-radius: 8px;
  text-align: center;
}
input:focus{
  background-color: transparent !important;
}
/* input:-webkit-autofill{
  background-color: transparent !important;
  color:transparent !important;
} */
.LoginPage-Main-wrapper {
  height:100vh;
  align-items: center;
  align-self: center;
  vertical-align: middle;
  /* background-color: white; */
  display: flex;
  width:100%;
}
.Login-page-form .css-1a1fmpi-MuiInputBase-root-MuiInput-root{
  width:95%;
  margin:auto;
}
.Login-page-form-logo-image {
  width: 25%;
}
.Loginpage-main-form-inner-div{
margin-top:44px !important;
}
.Login-form-p {
  margin: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: rgb(1,115,212);
  font-weight: 600;
  margin-top:10px  !important;
  margin-bottom:0px !important; 
}
.Login-page-form h6 {
  margin: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-size: 17px;
  margin-top: 0px !important;
}
.Loginpage\=-main-form {
  margin: 24px;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 13px;
  margin-top: 46px;
}
.Login-page-form .css-1x51dt5-MuiInputBase-input-MuiInput-input{
  font-size: 19px !important;
  font-weight: 600 !important;
  color:rgb(189,196,224) !important;
  letter-spacing: 0px !important;
}
.Login-page-form .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 15.9px;
  font-family: 'Poppins', sans-serif;
  color:rgb(189,196,224);
  font-weight: 500;
}

.Loginpage-btn-wrapper-btn{
  color: black !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size:20px !important;
  background-color: rgb(189,196,224) !important;
  padding: 10px !important;
  border-radius: 7px !important;
  width: 100% !important;
  border:none;
  font-weight: 700;
}
.Login-page-form .css-1d1r5q-MuiFormHelperText-root.Mui-error{
font-weight: 400;
font-size: 10px;
padding-left:39px;
}
.Login-page-form .css-dmmspl-MuiFormGroup-root{
  padding-left: 15px
}
img.Logout-image {
  width: 100%;
  margin-left: 2px;
}
.Login-page-remember-checkbox{
width:25px;
height:25px;
}
.Login-page-remember-checkbox-span{
  margin:10px;
  color:rgb(189,196,224);
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input:-webkit-autofill{
  background-color: transparent !important;
}
/* =============================End================================================================================================================================================================================================================== */


/* ==================SelectrFleetPage=======================================================================================================================================================   */
.SelectFleetPage-content-wrapper-Main{
  display: flex;
  flex-wrap: wrap;
}
.SelectFleetPage-inner-li-div{
  width:400px;
  margin:7px;
  cursor: pointer;
  border-top: none !important;
  cursor: pointer;
  position: relative !important;
}
.SelectFleetPage-content-wrapper-Main.border-red {
  justify-content: flex-start;
  width: 407px;
  margin:5px;
  background-color:rgb(40,47,66);
}
.Select-page-fleetName {
  color: #b7b7b7 !important;
  font-size: 16px;
}
.SelectFleetPage-content-wrapper-Main .Dashboard-content-div-one-left-side-h3{
  text-align: start !important;
  /* border-bottom: 3px solid #efefef; */
  padding: 6px;
  font-size: 13px;
  color:white !important;
}
.SelectFleetPage-content-wrapper-Main .d-flexx-center {
  padding-right: 30px;
  padding-left: 30px;
}
.container.SelectFleetPage-wrapper-Main {
  margin-top: 3rem;
}
.container.SelectFleetPage-wrapper-Main .SelectPageMain-Heading {
  margin: 22px;
  margin-left: 0px;
}
.SelectFleet-Page-p-tag {
  font-size: 21px;
}
.SelectFleet-Page-h5-tag {
  font-weight: 800;
}
.SelectFleetPage-inner-li-div-robot-id{
margin-bottom:0px;
}
.SelectPage-robotList-wrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: rgb(40,47,66);
  padding:11px;
  width: 100%;
  justify-content: flex-start;
}
.Select-Page-Robot-Message-Heading{
  color:#ffffff !important;
  text-transform: uppercase !important;
  font-size:15px;
}
.SelectFleet-page-Error-box-wrapper{
display: flex;
justify-content: flex-start;
align-items: center;
} 
/* ==================End=======================================================================================================================================================   */
/* =====================Header===================================================================================================================================================================== */
.Header-Add-new-order-btn{
  border-radius: 30px;
}
.Header-Main-wrapper {
  /* box-shadow: 0px 16px 14px 5px lightgrey; */
  box-shadow: 0 0 80px rgba(0,0,0,0.1);
  padding: 14px 65px;
}
.HeaderMain-logo-image{
width:100%;
}

.HeaderMain-logo-image {
  width: 7%;
}
button.Header-Add-new-order-btn {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 6px 20px !important;
  background-color: rgb(1,115,212) !important;
  color: #ffffff !important;
  border-radius: 20px !important;
}
.container-fluid.SelectFleetPage-wrapper-Main {
  padding-left: 55px;
  padding-right: 55px;
  margin-bottom: 6rem;
}
.row.justify-content-between{
justify-content: end;
}
/* =========================End================================================================================================================================================================================= */
/* =============================Disengage Modal======================================================================================================================================================================================== */
.Modal-wrapper-p{
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 39px;
letter-spacing: 1.6000000238px;
color: #fff;
text-align: center;
margin-bottom: 20px;
}

/* ==================================End====================================================================================================================================================================================================================== */
.No-robots-available{
font-size:13px;
color:white;
}
.RobotActions-content-wrapper-div {
  color: #ffffff !important;
  padding: 19px;
}
.RobotActions-content-wrapper-div h3{
  font-size:15px;
  margin-bottom:10px;
}
.RobotActions-content-wrapper-div p{
  font-size:13px;
  margin-bottom:0px;
}
.RobotActions-button-wrapper {
  margin-top: 20px;
}
.RobotActions-button-wrapper button{
  border:none;
  color:white !important;
  border-radius:5px;
  font-size:13px;
  padding:5px 15px;
  margin:5px;
}
.RobotActions-Start-btn{
background-color: rgb(40,167,69);
}
.RobotActions-cancel-btn{
background-color: rgb(220,53,69);
}
/* =============================Responsive================================================================================================================================================================================================================== */
/* =============================End================================================================================================================================================================================================================== */
#map { 
  height: 100vh;
}

.leaflet-popup-content-wrapper {
  width: 100%;
}
